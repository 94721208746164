import { Button } from '@homeproved/shared/ui';
import { MenuItem, Typography, MenuList } from '@material-ui/core';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const SuggestionTitle = styled(Typography)`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
  padding-top: ${({ theme }) => theme.spacing(1)}px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  font-size: 1.2rem;
  border-top: 1px solid ${({ theme }) => theme.palette.grey[300]};
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
`;

export const SuggestionItems = styled(MenuList)`
  display: flex;
  flex-direction: column;
  width: 100%
  align-items: center;
`;

export const AddCompany = styled(MenuList)`
  margin-left: 5px;
  cursor: pointer;
  color: #d9023a;
`;

export const SuggestionItem = styled(MenuItem)`
  padding-top: ${({ theme }) => theme.spacing(0.5)}px;
  padding-bottom: ${({ theme }) => theme.spacing(0.5)}px;
  padding-left: ${({ theme }) => theme.spacing(6)}px;
  padding-right: ${({ theme }) => theme.spacing(6)}px;
  min-height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
  @media (max-width: 420px) {
    padding-left: 28px;
    padding-right: 28px;
  }
`;

export const CompanyName = styled(Typography)`
  font-size: 1.2rem;
  min-height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  display: -webkit-box;
  justify-content: space-between;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
`;

export const CitySpan = styled(Typography)`
  font-size: 1.2rem;
  min-height: auto;
  overflow: hidden;
  font-style: italic;
  text-align: right;
  text-overflow: ellipsis;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  min-width: 100px;
`;

export const NoResult = styled(Typography)`
  font-size: 1.2rem;
  min-height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  padding-top: ${({ theme }) => theme.spacing(0.5)}px;
  padding-bottom: ${({ theme }) => theme.spacing(0.5)}px;
  display: flex;
  justify-content: center;
`;

export const FilteredList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;
export const FilteredListItem = styled.li`
  a {
    text-decoration: none;
    color: #000;
  }
  ${FilteredList} {
    padding-left: 4rem;
  }
`;

export const MoreResultsLink = styled.span`
  text-decoration: underline;
  font-size: 1.2rem;
  margin-right: 2rem;
  cursor: pointer;
`;

export const StyledButton = styled(({ sectorChecked, ...restProps }) => <Button {...restProps} />)`
  margin-bottom: 0.5rem;
  span {
    font-weight: ${({ sectorChecked }) => (sectorChecked ? 700 : 400)};
    text-align: left;
  }
  svg {
    transform: ${({ sectorChecked }) =>
      sectorChecked ? 'translateY(0) rotate(90deg)' : 'translateY(-40%)'};
    right: ${({ sectorChecked }) => (sectorChecked ? '1.7rem' : '1.5rem')};
  }
`;
