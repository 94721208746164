import {
  FilterMenu,
  InviteCard,
  ReviewCard,
  SectionTitle,
  theme,
  Pagination,
  InfinitePagination,
} from '@homeproved/shared/ui';
import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useUser } from '@homeproved/shared/feature-auth';
import { PaginationMeta, Review, useQueryFetch } from '@homeproved/shared/data-access';
import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import { useRouter } from 'next/router';
import { createURL, objectToQueryString, usePageScroll } from '@homeproved/shared/util';
import { useCurrentLanguage } from '@homeproved/shared/feature-i18n';

type ReviewsPageProps = {
  getPath: GetPathFunction;
};

const Wrapper = styled.div``;

const ContentWrapper = styled(({ mobile, ...restProps }) => <div {...restProps} />)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: ${({ mobile }) => (mobile ? 'column' : 'row')};
`;
const Side = styled(({ mobile, ...restProps }) => <div {...restProps} />)`
  width: ${({ mobile }) => (mobile ? 'auto' : '32rem')};
  padding-left: ${({ mobile }) => (mobile ? '0' : '5rem')};
  margin: ${({ mobile }) => (mobile ? '4rem -3rem 0' : '0')};
`;

const Message = styled(Typography)`
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
`;

const StyledGrid = styled(({ isSidebarOn, isDesktop, ...restProps }) => <Grid {...restProps} />)`
  display: flex;
  flex-direction: column;
  ${({ isSidebarOn, isDesktop }) =>
    isSidebarOn &&
    !isDesktop &&
    `
    max-width: 100%;
    flex-basis: 100%;
  `}
`;
const StyledGridContainer = styled(({ isSidebarOn, isDesktop, ...restProps }) => (
  <Grid {...restProps} />
))`
  display: flex;
  ${({ isSidebarOn, isDesktop }) =>
    isSidebarOn &&
    !isDesktop &&
    `
    flex-direction: column;
  `}
`;

export type ReviewData = {
  data: Review[];
  links: unknown;
  meta: unknown;
};

export const ReviewsPage: FC<ReviewsPageProps> = ({ getPath }) => {
  const { t } = useTranslation();
  const currentLanguage = useCurrentLanguage();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xs));
  const isSidebarOn = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.offCanvas));
  const isDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.md));
  const user = useUser();
  const company = user?.relations?.company?.data;
  const [apiRouteInit, setApiRouteInit] = useState(false);
  const [reviewsLoaded, setReviewsLoaded] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(currentLanguage);
  const { setPageScrollEnabled } = usePageScroll();

  const [apiRoute, setApiRoute] = useState('');

  const router = useRouter();

  const { date, rating, page, perPage } = router.query;

  const getApiRoute = () => {
    return apiRoute + objectToQueryString({ date, rating, page, perPage });
  };
  const { query } = useQueryFetch<ReviewData, unknown>('reviews', getApiRoute(), {
    options: {
      enabled: false,
    },
  });

  const navigateToReview = (slug: string, rid: number) => {
    router
      .push(
        getPath('/reviews/:id', {
          id: rid.toString(),
        })
      )
      .then();
  };

  const pushParams = useCallback(
    (params) => {
      setPageScrollEnabled(false);

      router
        .replace(
          {
            pathname: getPath(router.pathname),
            query: createURL(params),
          },
          undefined,
          { shallow: true }
        )
        .then(() => {
          setTimeout(() => {
            setPageScrollEnabled(true);
          }, 200);
        });
    },
    [router, setPageScrollEnabled, getPath]
  );

  useEffect(() => {
    if (!apiRouteInit && !!company) {
      setApiRoute(`/api/companies/${company.id}/reviews?`);
      setApiRouteInit(true);
    }
  }, [apiRouteInit, company]);

  useEffect(() => {
    if (apiRouteInit && !reviewsLoaded) {
      if (Object.keys(router.query).length === 0) {
        pushParams({
          date: 'desc',
          rating: '',
          page: 1,
          perPage: 10,
        });
      } else {
        query.refetch().then(({ status }) => {
          setReviewsLoaded(status === 'success');
        });
      }
    }
  }, [apiRouteInit, reviewsLoaded, query, router.query, pushParams]);

  const reviews = query.data?.data || [];
  const pagination = query.data?.meta as PaginationMeta;
  const filterOptionsInit = [
    {
      key: 'newOld',
      label: t('app.pro.pages.reviews.filter.newOld'),
      selected: true,
      handle: () => {
        setReviewsLoaded(false);
        pushParams({ rating: '', date: 'desc', page: 1, perPage: 10 });
      },
    },
    {
      key: 'oldNew',
      label: t('app.pro.pages.reviews.filter.oldNew'),
      selected: false,
      handle: () => {
        setReviewsLoaded(false);
        pushParams({ rating: '', date: 'asc', page: 1, perPage: 10 });
      },
    },
    {
      key: 'positive',
      label: t('app.pro.pages.reviews.filter.positive'),
      selected: false,
      handle: () => {
        setReviewsLoaded(false);
        pushParams({ rating: 'desc', date: '', page: 1, perPage: 10 });
      },
    },
    {
      key: 'negative',
      label: t('app.pro.pages.reviews.filter.negative'),
      selected: false,
      handle: () => {
        setReviewsLoaded(false);
        pushParams({ rating: 'asc', date: '', page: 1, perPage: 10 });
      },
    },
  ];

  const [filterOptions, setFilterOptions] = useState(filterOptionsInit);

  const setSelected = (label: string) => {
    setFilterOptions(
      filterOptions.map((filterOption) => {
        if (filterOption.label === label) {
          return {
            ...filterOption,
            selected: true,
          };
        }
        return {
          ...filterOption,
          selected: false,
        };
      })
    );
  };

  useEffect(() => {
    // translate filters when currentLanguage changes
    if (activeLanguage !== currentLanguage) {
      const currentFilters = [...filterOptions];
      for (let i = 0; i < currentFilters.length; i++) {
        currentFilters[i].label = t(`app.pro.pages.reviews.filter.${currentFilters[i].key}`);
      }
      setFilterOptions(currentFilters);
      setActiveLanguage(currentLanguage);
    }
  }, [t, currentLanguage, filterOptions, activeLanguage]);

  const goToPage = (page: number) => {
    pushParams({ ...router.query, page });
    setReviewsLoaded(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const loadMorePages = () => {
    pushParams({ ...router.query, page: 1, perPage: parseInt(perPage.toString()) + 10 });
    setReviewsLoaded(false);
  };

  if (!company) return <>{t('app.pro.pages.reviews.errors.companyNotFound')}</>;
  return (
    <Wrapper>
      <Box mb={2} mt={isMobile ? 1 : 0}>
        <SectionTitle
          label={t('app.pro.pages.reviews.title').replace(
            '%COUNT%',
            pagination?.total?.toString() ?? '...'
          )}
          textAlign={isMobile ? 'center' : 'left'}
          uppercase={true}
          font={'PTSans'}
        />
      </Box>
      <Box mb={4} display="flex" justifyContent={isMobile ? 'center' : 'left'}>
        {Object.keys(router.query).length > 0 && (
          <FilterMenu
            filterOptions={filterOptions}
            setSelected={setSelected}
            query={router.query}
          />
        )}
      </Box>
      <ContentWrapper mobile={isMobile}>
        <Box width={isMobile ? '100%' : 'calc(100% - 32rem)'}>
          <StyledGridContainer
            container
            spacing={4}
            isSidebarOn={isSidebarOn}
            isDesktop={isDesktop}
          >
            {reviewsLoaded && reviews.length === 0 && (
              <Message>{t('app.pro.pages.reviews.noReviewsFound')}</Message>
            )}
            {reviews.length > 0 &&
              reviews.map(
                (review) =>
                  review.data && (
                    <StyledGrid
                      isSidebarOn={isSidebarOn}
                      isDesktop={isDesktop}
                      item
                      xs={12}
                      sm={6}
                      key={review.data.id}
                    >
                      <ReviewCard
                        review={{
                          id: review.data.id,
                          files: review.data.files,
                          audio: review.data.audio,
                          video: review.data.video,
                          videoThumbnail: review.data.videoThumbnail,
                          isTranslation: review.data.originalLanguage !== currentLanguage,
                          picture: review.data.picture,
                          initials: review.data?.initials,
                          date: review.data.createdAt,
                          rating: review.data.rating,
                          description: review.data.description,
                          title: review.data.title,
                          companyId: company.id,
                          companySlug: company.slug,
                          screenName: review.data.screenName,
                        }}
                        href={ getPath('/reviews/:id', {
                          id: review.data.id.toString(),
                        })}
                        isMobile={isMobile}
                        showButtons
                        getComPath={getPath}
                        checked={review.data.status === 'approved'}
                        shared={!!review.data.lastSocialShare}
                        favorite={review.data.isFavorite}
                        answered={!!review.data.relations?.feedback?.data?.message}
                        isNew={!review.data.isReadByUser}
                        navigateToReview={navigateToReview}
                        teaser={true}
                        bigBalloon={true}
                        equalHeight
                      />
                    </StyledGrid>
                  )
              )}
          </StyledGridContainer>
          {pagination != null &&
            (isMobile ? (
              <InfinitePagination {...pagination} loadMore={loadMorePages} />
            ) : (
              <Pagination {...pagination} goToPage={goToPage} defaultPagesToShow={5} />
            ))}
        </Box>
        <Side mobile={isMobile}>
          <InviteCard isMobile={isMobile} inviteLink={getPath('/invitation')} />
        </Side>
      </ContentWrapper>
    </Wrapper>
  );
};
