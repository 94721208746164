import React, { FC, useEffect, useState } from 'react';
import { Box, Grow, Step, StepLabel, Stepper, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/router';
import {
  CompanyData,
  ReviewsApiFactory,
  useApiFactory,
  useMutationFetch,
  usePersistentData,
} from '@homeproved/shared/data-access';
import {
  Button,
  DancingScriptQuote,
  LogoIcon,
  Review,
  ReviewCard,
  TextRecaptcha,
} from '@homeproved/shared/ui';
import { PulseLoader } from 'react-spinners';
import { Slider } from './steps/Slider';
import { Description } from './steps/Description';
import { ProCon } from './steps/ProCon';
import { UserDataForm } from './steps/UserDataForm';
import { ButtonWrapper, FormFields, TooGoodNotice, LoadingWrapper, LoadingText } from './Atoms';
import { WriteReviewFormData, writeReviewFormSchema } from '@homeproved/shared/feature-forms';
import { Verification } from './steps/Verification';
import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styled from 'styled-components';
import { useLocalizedRoutes } from '@homeproved/com/feature-localized-routes';
import { Collab } from './steps/Collaberation';
import { convertFiles } from './fileConversion';
import { useSnackbar } from 'notistack';

type WriteReviewFormProps = {
  getComPath: GetPathFunction;
  company: CompanyData;
  onStep: (currentStep: number) => void;
  qr: boolean;
};

export type CustomProConPoint = {
  id: number;
  type: 'pro' | 'con';
  title: string;
  submitted: number;
};

const StyledStepper = styled(Stepper)`
  box-shadow: none;
  max-width: 100rem;
  margin: 0 auto;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  .MuiStepIcon-text {
    display: none;
  }
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    font-family: ${({ theme }) => theme.config.fonts.PTSans};
  }
  .MuiStepConnector-root {
    top: 10px;
    left: calc(-50% + 12px);
    right: calc(50% + 12px);
  };
  .MuiStepConnector-line {
    border-color: rgba(0, 0, 0, 0.38);
    border-top-width: 5px;
    border-radius: 0;
  }
  .MuiStepConnector-active,.MuiStepConnector-completed {
  .MuiStepConnector-line {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const StyledTextArea = styled(({ loading, ...restProps }) => <textarea {...restProps} />)`
  padding: 1rem;
  position: relative;
  border: 0.1rem solid #bebebe;
  border-radius: 0.5rem;
  font-family: pt-sans, Arial, sans-serif;
  width: 100%;
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
  cursor: ${({ loading }) => (loading ? 'wait' : 'text')};
  background: ${({ loading }) => (loading ? '#f2f2f2' : '#fff')};
`;

const StyledReviewHeader = styled.div`
  font-family: ${({ theme }) => theme.config.fonts.Cabrito};
  width: 100%;
  background: ${({ theme }) => theme.config.gradients.red};
  display: flex;
  flex-wrap: wrap;
  svg {
    margin: 2rem;
  }
  h2 {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    font-size: 20px;
    margin: 0 auto;
    padding: 2rem;
    @media screen and (max-width: 900px) {
      margin: 0 2rem;
      text-align: center;
      max-width: calc(100% - 4rem);
    }
  }
  margin-bottom: 4rem;
  align-items: center;
  @media screen and (max-width: 900px) {
    margin-bottom: 1rem;
  }
`;

const Corners = styled(({ isMobile, ...restProps }) => <Box {...restProps} />)`
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? '25px' : '50px')};
  background: ${({ theme }) => theme.palette.primary.contrastText};
  border-top-left-radius: ${({ isMobile }) => (isMobile ? '25px' : '50px')};
  border-top-right-radius: ${({ isMobile }) => (isMobile ? '25px' : '50px')};
`;
export const WriteReviewForm: FC<WriteReviewFormProps> = ({ qr, getComPath, company, onStep }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xs));
  const [score, setScore] = useState(4);
  const [selectedImages, setSelectedImages] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [showNextButton, setShowNextButton] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [newCustomProConPoints, setNewCustomProConPoints] = useState<string[]>([]);
  const { setSubmittedReview } = usePersistentData();
  const router = useRouter();
  const { getPath } = useLocalizedRoutes();
  const { enqueueSnackbar } = useSnackbar();
  const reviewsApi = useApiFactory(ReviewsApiFactory);
  const { mutation: postReviewMutation } = useMutationFetch('postReview', reviewsApi.apiReviewPost);

  const methods = useForm<WriteReviewFormData>({
    resolver: zodResolver(writeReviewFormSchema),
  });
  const { executeRecaptcha } = useGoogleReCaptcha();

  const goToCompanyPage = () => {
    router
      .push(
        getComPath('/company/:slug/reviews', {
          slug: company.slug,
        })
      )
      .then();
  };

  const submitReview = () => {
    const data = methods.getValues();
    executeRecaptcha('writeReview').then(async () => {
      const form = {
        companyId: company.id,
        typeOfWork: data.description,
        description: data.collaberation,
        rating: (score * 2).toString(),
        screenName: data.screenName,
        title: data.title,
        email: data.email,
        city: data.location,
        postalCode: data.postalCode,
        placeId: data.placeId,
        pointsProConIds: newCustomProConPoints,
        origin: 'web',
        files: await convertFiles(selectedImages),
      };

      const getFormData = (object) =>
        Object.keys(object).reduce((formData, key) => {
          if (Array.isArray(object[key]) && key !== 'files') {
            object[key].forEach((item, index) => {
              formData.append(`${key}[${index}]`, JSON.stringify(item));
            });
            return formData;
          }
          if (key === 'files') {
            object[key].forEach((item, index) => {
              if (item?.multipart) {
                formData.append(`${key}[${index}][multipart]`, item.multipart);
              }
              if (item?.thumbnail) {
                formData.append(`${key}[${index}][thumbnail]`, item.thumbnail);
              }
            });
            return formData;
          }
          formData.append(key, object[key]);
          return formData;
        }, new FormData());

      postReviewMutation.mutate({
        data: getFormData(form),
      });
      setSubmitted(true);
    });
  };

  useEffect(() => {
    if (postReviewMutation.isSuccess) {
      setSubmittedReview(postReviewMutation.data.data);
    }
    if (postReviewMutation.isError) {
      setSubmitted(false);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response = postReviewMutation?.error?.response;
      const errors = Object.keys(response?.data?.errors);
      enqueueSnackbar(
        `er is iets misgelopen , controleer de gegevens en probeer het opnieuw :
        ${errors.map((error) => response?.data.errors[error][0])}`,
        {
          variant: 'error',
        }
      );
    }
  }, [postReviewMutation.isSuccess, postReviewMutation.data, postReviewMutation.isError]);

  const handleSliderChange = (value: number) => {
    setScore(value);
    setShowNextButton(true);
  };

  const handleNextStep = async () => {
    let valid = true;
    if (currentStep === 2) {
      valid = await methods.trigger(['description', 'location', 'placeId']);
    }
    if (currentStep === 4) {
      valid = await methods.trigger(['collaberation', 'screenName']);
    }
    if (valid) {
      setCurrentStep(currentStep + 1);
      onStep(currentStep + 1);
    }
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
    onStep(currentStep - 1);
  };

  return executeRecaptcha ? (
    <>
      {!qr && isMobile && currentStep > 1 && !postReviewMutation.isSuccess && !showReview && (
        <Box ml={3} mb={1}>
          <Button variant="text" size="medium" onClick={handlePreviousStep}>
            {t('reviews.write.previousBtn', 'Vorige')}
          </Button>
        </Box>
      )}
      {qr && (
        <StyledReviewHeader>
          <LogoIcon onClick={() => router.push(getPath('/'))} />
          <h2>
            {t('reviews.write.companyTitle', 'schrijf een review over %COMPANY_NAME%').replace(
              '%COMPANY_NAME%',
              company.name
            )}
          </h2>
          {isMobile && currentStep > 1 && (
            <Box ml={3} mb={1}>
              <Button color="white" variant="text" size="medium" onClick={handlePreviousStep}>
                {t('reviews.write.previousBtn', 'Vorige')}
              </Button>
            </Box>
          )}
          <Corners isMobile={isMobile} />
        </StyledReviewHeader>
      )}

      <StyledStepper activeStep={currentStep - 1} alternativeLabel>
        <Step key={'score'}>
          <StepLabel>{isMobile ? '' : 'Score'}</StepLabel>
        </Step>
        <Step key={'beschrijving'}>
          <StepLabel>{isMobile ? '' : 'Werken'}</StepLabel>
        </Step>
        <Step key={'kernwoorden'}>
          <StepLabel>{isMobile ? '' : 'Kernwoorden'}</StepLabel>
        </Step>
        <Step key={'samenwerking'}>
          <StepLabel>{isMobile ? '' : 'Samenwerking'}</StepLabel>
        </Step>
        <Step key={'validatie'}>
          <StepLabel>{isMobile ? '' : 'Validatie'}</StepLabel>
        </Step>
      </StyledStepper>
      <Box maxWidth="50rem" margin="0 auto">
        <form onSubmit={methods.handleSubmit(submitReview)}>
          <FormProvider {...methods}>
            <FormFields noPadding mobile={isMobile}>
              {currentStep === 1 && (
                <Slider
                  isActive={currentStep === 1}
                  company={company}
                  onChange={handleSliderChange}
                  readOnly={submitted}
                  isMobile={isMobile}
                />
              )}
              {currentStep === 2 && (
                <Description
                  company={company.country}
                  isActive={currentStep === 2}
                  readOnly={submitted}
                  onImagesChange={setSelectedImages}
                />
              )}
              {currentStep === 3 && (
                <ProCon
                  proConPoints={newCustomProConPoints}
                  isActive={currentStep === 3}
                  setProConPoints={setNewCustomProConPoints}
                />
              )}
              {currentStep === 4 && (
                <Collab
                  companyId={company.id}
                  proConPoints={newCustomProConPoints}
                  isActive={currentStep === 4}
                  score={(score * 2).toString()}
                  readOnly={submitted}
                />
              )}
              {currentStep === 5 && (
                <UserDataForm
                  setAccepted={(checked) => {
                    methods.setValue('acceptPolicy', checked);
                  }}
                  isActive={currentStep === 5 && !showReview && !postReviewMutation.isSuccess}
                  getComPath={getComPath}
                  readOnly={submitted}
                  isMobile={isMobile}
                />
              )}
            </FormFields>
            <Grow in={score === 10 && currentStep === 1} mountOnEnter unmountOnExit>
              <TooGoodNotice>
                <DancingScriptQuote size={2.4} quote={t('reviews.write.sliderMax')} />
              </TooGoodNotice>
            </Grow>
            {showNextButton && (
              <>
                <ButtonWrapper sticky={currentStep === 3} fullwidth={currentStep === 1 || isMobile}>
                  {!isMobile &&
                    currentStep > 1 &&
                    !postReviewMutation.isSuccess &&
                    !postReviewMutation.isLoading && (
                      <Button variant="text" arrow="left" size="large" onClick={handlePreviousStep}>
                        {t('reviews.write.previousBtn', 'Vorige')}
                      </Button>
                    )}
                  {currentStep <= 4 && !postReviewMutation.isSuccess && (
                    <Button size="large" onClick={handleNextStep}>
                      {t('reviews.write.nextBtn')}
                    </Button>
                  )}
                  {currentStep === 5 &&
                    !postReviewMutation.isSuccess &&
                    !postReviewMutation.isLoading &&
                    !showReview && (
                      <Button size="large" type="submit">
                        {t('reviews.write.verificationBtn')}
                      </Button>
                    )}
                </ButtonWrapper>
                <ButtonWrapper sticky={false} fullwidth>
                  {currentStep === 5 &&
                    !postReviewMutation.isSuccess &&
                    postReviewMutation.isLoading && (
                      <Button arrow="none" size="large">
                        <LoadingWrapper>
                          <LoadingText>{t('reviews.write.loadingBtn')}</LoadingText>
                          <PulseLoader color={theme.palette.grey['A400']} loading={true} size={4} />
                        </LoadingWrapper>
                      </Button>
                    )}
                </ButtonWrapper>
              </>
            )}
            {currentStep === 5 &&
              !postReviewMutation.isSuccess &&
              !postReviewMutation.isLoading &&
              !showReview && (
                <Box
                  p={1}
                  width="100%"
                  mb={2}
                  display="flex"
                  justifyContent={isMobile ? 'center' : 'start'}
                >
                  <TextRecaptcha />
                </Box>
              )}
          </FormProvider>
        </form>
        {showReview && (
          <>
            <Box textAlign="center">
              <h2>
                Klaar! <br /> de review werd gevalideerd
              </h2>
            </Box>
            <Box
              p={2}
              maxWidth="50rem"
              margin="0 auto"
              onClick={() =>
                router.push(
                  getComPath('/company/:slug/review/:rid', {
                    slug: company.slug,
                    rid: postReviewMutation.data.data.id.toString(),
                  })
                )
              }
            >
              <ReviewCard
                review={
                  {
                    ...postReviewMutation.data.data,
                    ...{
                      isTranslation: false,
                      date: postReviewMutation.data.data.createdAt,
                      companyId: company.id,
                      companySlug: company.slug,
                    },
                  } as Review
                }
                proCons={postReviewMutation.data.data.relations.proConPoints}
                isMobile={isMobile}
                href={getComPath('/company/:slug/review/:rid', {
                  slug: company.slug,
                  rid: postReviewMutation.data.data.id.toString(),
                })}
              />
            </Box>
            <Box display="flex" justifyContent="center" p={2}>
              <Button onClick={goToCompanyPage} size="large">
                Ga naar bedrijfsprofiel
              </Button>
            </Box>
          </>
        )}
        {postReviewMutation.isSuccess && !showReview && (
          <Verification
            reviewId={postReviewMutation.data.data.id.toString()}
            email={methods.getValues().email || 'sam@test.com'}
            onSuccess={() => setShowReview(true)}
          />
        )}
      </Box>
    </>
  ) : null;
};
