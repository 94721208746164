import React, { FC, useState } from 'react';
import { Stars, ScoreSlider } from '@homeproved/shared/ui';
import { Result, SliderWrapper, StyledRating } from '../Atoms';
import { useTranslation } from 'react-i18next';
import { CompanyData } from '@homeproved/shared/data-access';
import { Box } from '@material-ui/core';

type SliderProps = {
  company: CompanyData;
  onChange: (value: number) => void;
  readOnly: boolean;
  isMobile: boolean;
  isActive: boolean;
};

export const Slider: FC<SliderProps> = ({ company, onChange, readOnly, isActive, isMobile }) => {
  const { t } = useTranslation();
  const [score, setScore] = useState(4);

  return (
    <SliderWrapper isActive={isActive} mobile={isMobile}>
      {isMobile ? (
        <h4>{t('reviews.write.sliderTitle').replace('%COMPANY_NAME%', company.name)}</h4>
      ) : (
        <h2>{t('reviews.write.sliderTitle').replace('%COMPANY_NAME%', company.name)}</h2>
      )}
      <Result>
        <Box display={'flex'} justifyContent={'center'} width="100%">
          <StyledRating size={6} value={score} max={5} />
        </Box>
        <Stars count={score * 2} size={isMobile ? 3 : 4} />
      </Result>
      <ScoreSlider
        detailed
        value={4}
        minValue={1}
        maxValue={5}
        onChange={(value) => {
          setScore(value);
          onChange(value);
        }}
        disabled={readOnly}
      />
    </SliderWrapper>
  );
};
