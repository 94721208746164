import { ErrorMessage, Input } from '@homeproved/shared/feature-forms';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReadOnlyHiddenField, ReadOnlyValue } from '../Atoms';
import { Title } from './description/Atoms';
import { ContentUpload, ImageList } from './ContentUpload';
import { useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/core';
import { StyledTextArea } from '../WriteReviewForm';
import { debounce } from 'lodash';
import { CompaniesApiFactory, useApiFactory, useQueryFetch } from '@homeproved/shared/data-access';

type Props = {
  company: string;
  isActive: boolean;
  readOnly: boolean;
  onImagesChange: (images: ImageList) => void;
};

const useDebounce = (callback) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  return useMemo(() => {
    const func = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref.current?.();
    };

    return debounce(func, 500);
  }, []);
};

export const Description: FC<Props> = ({ company, readOnly, onImagesChange, isActive }) => {
  const { t } = useTranslation();
  const { register, errors, getValues, setValue, setError, clearErrors } = useFormContext();
  const [placeId, setPlaceId] = React.useState('');
  const [postalCode, setPostalCode] = React.useState('2000');
  const [locationSuggestions, setLocationSuggestions] = React.useState([]);
  const [location, setLocation] = React.useState('');
  const [query, setQuery] = React.useState('');
  const debouncedQuery = useDebounce(() => setQuery(location));
  const companiesApi = useApiFactory(CompaniesApiFactory);
  const { query: companyLocation } = useQueryFetch(['companyLocation', query], () => {
    if (!query || query.length < 2) return;
    return companiesApi.apiCompanyLocationGet(query);
  });
  const { query: geocode } = useQueryFetch(['geocode', query], () => {
    if (!query || query.length < 2) return;
    return companiesApi.apiCompanyLocationGeoCodeGet(query);
  });

  React.useEffect(() => {
    setPostalCode(geocode.data?.data?.postalCode || '');
    setValue('postalCode', geocode.data?.data?.postalCode || '');
    setPlaceId(geocode.data?.data?.placeId || '');
    setValue('placeId', geocode.data?.data?.placeId || '');
  }, [geocode?.data]);

  React.useEffect(() => {
    if (companyLocation?.data) {
      setLocationSuggestions(companyLocation?.data?.data);
    }
    if (companyLocation?.data || !companyLocation?.data?.length) {
      setError('location', {
        type: 'manual',
        message: 'Gelieve een geldige locatie in te geven',
      });
    }
  }, [companyLocation?.data, companyLocation?.data]);

  const locationSearch = (e) => {
    const location = e.target.value;
    setLocation(location);
    debouncedQuery(location);
    setValue('location', location);
  };

  const handleLocationSelect = (suggestion) => {
    const { city } = suggestion;
    setValue('location', city);
    setLocation(city);
    clearErrors('location');
    setLocationSuggestions(null);
  };

  return (
    <Box display={isActive ? 'block' : 'none'}>
      <Title>{`${t('reviews.write.description.title')}`}</Title>
      <ReadOnlyHiddenField readOnly>
        <input readOnly name="postalCode" {...register('postalCode')} defaultValue={postalCode} />
        <input readOnly name="placeId" {...register('placeId')} defaultValue={placeId} />
      </ReadOnlyHiddenField>
      <ReadOnlyHiddenField readOnly={readOnly}>
        <StyledTextArea
          rows={5}
          onChange={(e) => setValue('description', e.target.value)}
          defaultValue={getValues().description}
          name="description"
          {...register('description')}
          placeholder={t('reviews.write.description.placeholder', 'Schrijf hier je review')}
        />
        {errors.description && <ErrorMessage>{t(errors.description.message)}</ErrorMessage>}
      </ReadOnlyHiddenField>
      {readOnly && <ReadOnlyValue>{getValues().description}</ReadOnlyValue>}

      <Box mt={2}>
        <Title>{`${t(
          'reviews.write.description.location',
          'Waar zijn de werken uitgevoerd?'
        )}`}</Title>
        <ReadOnlyHiddenField readOnly={readOnly}>
          <Input
            name="location"
            autoComplete="off"
            defaultValue={getValues().location}
            value={location}
            placeholder={t('reviews.write.description.location.placeholder', 'Geef plaatsnaam in')}
            onChange={locationSearch}
            {...register('location')}
          />
          <Box border="0.1rem solid #BEBEBE" marginTop={-1}>
            {locationSuggestions?.map((suggestion) => (
              <Box
                p={1}
                key={suggestion}
                onClick={() => {
                  handleLocationSelect(suggestion);
                }}
              >
                {suggestion.description}
              </Box>
            ))}
          </Box>
          {errors.location && <ErrorMessage>{t(errors.location.message)}</ErrorMessage>}
        </ReadOnlyHiddenField>
        {readOnly && <ReadOnlyValue>{getValues().location}</ReadOnlyValue>}
      </Box>
      <ContentUpload readOnly={readOnly} onChange={onImagesChange} />
    </Box>
  );
};
