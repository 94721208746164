import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import styled from 'styled-components';
import { Box, CircularProgress, useTheme } from '@material-ui/core';
import { useProCons } from '../../hooks/useProCons';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  width: 100%;
  margin-bottom: 1rem;
`;

const StyledToggleButton = styled(({ pro, selected, ...restProps }) => (
  <ToggleButton {...restProps} />
))`
  width: 100%;
  border-radius: 1rem;
  padding: 1rem;
  height: 40px;
  font-weight: 700;
  font-size: 1.6rem;
  text-transform: capitalize;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  color: ${({ theme, pro, selected }) => {
    if (pro && selected) {
      return theme.palette.green.main;
    }
    if (!pro && selected) {
      return theme.palette.red.main;
    }
    return '#AAAAAA';
  }};
  background-color: ${({ pro, selected, theme }) => {
    if (pro && selected) {
      return theme.palette.green.light;
    }
    if (!pro && selected) {
      return theme.palette.red.light;
    }
    return '#FFFFFFEB';
  }};
  border-color: ${({ theme, pro, selected }) => {
    if (pro && selected) {
      return theme.palette.green.main;
    }
    if (!pro && selected) {
      return theme.palette.red.main;
    }
    return '#CED4DA';
  }};
  &:hover {
    background-color: ${({ pro, selected, theme }) => {
      if (pro && selected) {
        return theme.palette.green.light;
      }
      if (!pro && selected) {
        return theme.palette.red.light;
      }
      return '#FFFFFFEB';
    }};
  }
  font: ${({ theme }) => theme.config.fonts.PTSans};
  &.MuiToggleButtonGroup-groupedHorizontal:not(:last-child) {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  &.MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
    margin-left: 0;
    border-left: 1px solid;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  &.MuiToggleButtonGroup-groupedHorizontal {
    border: 1px solid;
    border-color: ${({ theme, pro, selected }) => {
      if (pro && selected) {
        return theme.palette.green.main;
      }
      if (!pro && selected) {
        return theme.palette.red.main;
      }
      return '#CED4DA';
    }};
`;

const Divider = styled.span`
  padding: 1rem;
`;

const H3 = styled.h3`
  margin-top: 0;
  text-align: center;
`;

type ProConProps = {
  proConPoints: string[];
  isActive: boolean;
  setProConPoints: (proCon: string[]) => void;
};

export const ProCon: FC<ProConProps> = ({ proConPoints = [], isActive, setProConPoints }) => {
  const { t } = useTranslation();
  const { data: proConList, isSuccess } = useProCons();
  const theme = useTheme();

  const handleChange = (option, proConValue: string) => {
    const pro = option.data?.id;
    const con = option.data?.relations?.counter?.data?.id;

    if (proConValue === null) {
      setProConPoints(proConPoints.filter((value) => value !== pro && value !== con));
      return;
    }

    const oldValue = proConValue === pro ? con : pro;
    const newProCon = proConPoints.filter((value) => value !== oldValue);

    newProCon.push(proConValue);

    setProConPoints(newProCon);

    console.log('proCon', newProCon);
  };

  return (
    <Box fontFamily={theme.config.fonts.PTSans} display={isActive ? 'block' : 'none'}>
      <H3>{t('reviews.write.procon.title', 'Duid gepaste kernwoorden aan')}</H3>

      {isSuccess ? (
        proConList.data?.map((category) => (
          <>
            <h4>{category.data.label}</h4>
            {category.data.points?.map((point) => (
              <StyledToggleButtonGroup
                color="primary"
                exclusive
                key={point.data?.id}
                value={proConPoints?.find(
                  (value) =>
                    value === point.data?.id || value === point.data?.relations?.counter?.data?.id
                )}
                onChange={(e, selected) => handleChange(point, selected)}
              >
                <StyledToggleButton
                  pro
                  value={point.data?.id}
                  selected={proConPoints?.includes(point.data?.id)}
                >
                  {point.data?.title}
                </StyledToggleButton>
                <Divider>of</Divider>
                <StyledToggleButton
                  value={point.data?.relations?.counter?.data?.id}
                  selected={proConPoints?.includes(point.data?.relations?.counter?.data?.id)}
                >
                  {point.data?.relations?.counter?.data?.title}
                </StyledToggleButton>
              </StyledToggleButtonGroup>
            ))}
          </>
        ))
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};
