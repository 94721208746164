import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator';

function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export async function convertFiles(files) {
  const array = [];
  for (let i = 0; i < Object.keys(files).length; i++) {
    const element = files[i];
    if (element?.type?.includes('video')) {
      // Get thumbnail
      let thumbnail: File | undefined;
      try {
        const thumbnailArray = await generateVideoThumbnails(element, 1, 'video');
        thumbnail = dataURLtoFile(thumbnailArray[1], 'thumbnail.png');
      } catch (e) {
        console.error(`Cannot make thumbnail: ${e}`);
      }
      array.push({
        multipart: element,
        thumbnail,
      });
    } else {
      array.push({
        multipart: element,
      });
    }
  }
  return array;
}
