import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AcceptPolicyField, ErrorMessage, Input } from '@homeproved/shared/feature-forms';
import { useFormContext } from 'react-hook-form';
import { Wrapper, FormFields, Field, Title } from './user-data/Atoms';
import { ReadOnlyHiddenField, ReadOnlyValue } from '../Atoms';
import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import { Box } from '@material-ui/core';
import styled from 'styled-components';

type UserDataFormProps = {
  getComPath: GetPathFunction;
  readOnly: boolean;
  isMobile: boolean;
  isActive: boolean;
  setAccepted?: (checked: boolean) => void;
};

const StyledWrapper = styled(({ isActive, ...restProps }) => <Wrapper {...restProps} />)`
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  justify-content: center;
  flex-wrap: wrap;
  input {
    width: 100%;
  }
`;

export const UserDataForm: FC<UserDataFormProps> = ({
  getComPath,
  readOnly,
  isMobile,
  isActive,
  setAccepted,
}) => {
  const { t } = useTranslation();
  const { register, control, errors, getValues, setValue } = useFormContext();
  const baseTranslationKey = 'reviews.write.validation';

  return (
    <StyledWrapper isActive={isActive}>
      <Box width="100%" display="flex" justifyContent="center" mb="1rem">
        <img src={'/Invite.svg'} alt="invite" loading="lazy" />
      </Box>
      <Title>{t(`${baseTranslationKey}.title`, 'Review valideren via e-mail')}</Title>
      <Box width="100%">
        <FormFields mobile={isMobile}>
          <Box width="100%" display="flex" mb={2} justifyContent="center">
            <Field readOnly={readOnly} mobile={isMobile}>
              <ReadOnlyHiddenField readOnly={readOnly}>
                <Input
                  placeholder={'user@example.com'}
                  name="email"
                  onChange={(e) => setValue('email', e.target.value)}
                  {...register('email')}
                />
                {errors.email && <ErrorMessage>{t(errors.email.message)}</ErrorMessage>}
              </ReadOnlyHiddenField>
              {readOnly && <ReadOnlyValue bold>{getValues().email}</ReadOnlyValue>}
            </Field>
          </Box>
        </FormFields>
      </Box>
      {!readOnly && (
        <>
          <AcceptPolicyField onChange={setAccepted} comGetPath={getComPath} control={control} />
          {errors.acceptPolicy && <ErrorMessage>{t(errors.acceptPolicy.message)}</ErrorMessage>}
        </>
      )}
    </StyledWrapper>
  );
};
