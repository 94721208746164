import React, { FC, useState } from 'react';
import { connectAutoComplete } from 'react-instantsearch-dom';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { AutocompleteExposed, AutocompleteProvided, BasicDoc } from 'react-instantsearch-core';
import { FlyoutMenu, Search, SearchProps } from '@homeproved/shared/ui';
import { Box } from '@material-ui/core';
import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import {
  SuggestionTitle,
  SuggestionItem,
  Wrapper,
  SuggestionItems,
  CitySpan,
  NoResult,
  AddCompany,
  CompanyName,
} from './Atoms';

type OwnProps = {
  searchMode: SearchProps['mode'];
  placeholder: SearchProps['placeholder'];
  getPath: GetPathFunction;
  onToggleOffCanvas?: () => void;
};
type Props = AutocompleteProvided<BasicDoc> & OwnProps;

const Autocomplete: FC<Props> = ({
  hits,
  currentRefinement,
  refine,
  searchMode,
  placeholder,
  getPath,
  onToggleOffCanvas,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');

  const results = (
    <Wrapper>
      <SuggestionItems>
        {hits?.length ? (
          hits.map((hit) => (
            <Link href={getPath('/write-review/:slug', { slug: hit.slug })} key={hit.objectID}>
              <SuggestionItem button onClick={onToggleOffCanvas}>
                <CompanyName>{hit.name}</CompanyName>
                <CitySpan>{hit?.city ?? ''}</CitySpan>
              </SuggestionItem>
            </Link>
          ))
        ) : (
          <NoResult>{t('shared.form.address.googlePlaces.noOptions')}</NoResult>
        )}
      </SuggestionItems>
      <SuggestionTitle>
        {t('activityPicker.requestCategory.notFound')}
        <Link href={getPath('/add-company')}>
          <AddCompany>+ {t('app.com.pages.addCompany.title')}</AddCompany>
        </Link>
      </SuggestionTitle>
    </Wrapper>
  );

  return (
    <Box position="relative">
      <Search
        mode={searchMode}
        onChange={(data) => {
          setSearchTerm(data.searchTerm);
          refine(data.searchTerm);
        }}
        value={searchTerm}
        placeholder={placeholder}
      />
      {searchMode !== 'flyoutMenu' ? (
        <FlyoutMenu open={Boolean(currentRefinement)} fullWidth>
          {results}
        </FlyoutMenu>
      ) : currentRefinement ? (
        <div>{results}</div>
      ) : null}
    </Box>
  );
};

export const CustomAutocomplete = connectAutoComplete(Autocomplete) as React.ComponentClass<
  AutocompleteExposed & OwnProps
>;
