import React, { FC } from 'react';
import {
  AuthorImage,
  Body,
  Date,
  Name,
  ProConWrapper,
  Score,
  Title,
  Top,
  Wrapper,
  ProConTile,
  ProConTileText,
} from './Atoms';
import { ProConPoint } from '@homeproved/shared/data-access';
import moment from 'moment';
import 'moment/locale/nl-be';
import { Stars } from '@homeproved/shared/ui';
import { Box } from '@material-ui/core';

export interface ReviewPreviewProps {
  initials: string;
  screenName: string;
  score: number;
  title: string;
  date: string;
  body: string;
  proConPoints: ProConPoint[];
}

export const ReviewPreview: FC<ReviewPreviewProps> = ({
  initials,
  screenName,
  score,
  title,
  date,
  body,
  proConPoints,
}) => {
  return (
    <Wrapper>
      <Top>
        <AuthorImage>{initials}</AuthorImage>
        <div>
          <Name>{screenName}</Name>
          <Stars size={2} count={score} />
        </div>
        <Date>{moment(date).format('DD/MM/YY')}</Date>
      </Top>
      <Title>{title}</Title>
      <Body>{body}</Body>
      {proConPoints.length > 0 && (
        <ProConWrapper>
          {proConPoints.map((item, index) => (
            <ProConTile key={index} con={item.data.type === 'con'}>
              <ProConTileText variant="body1">{item.data.title}</ProConTileText>
            </ProConTile>
          ))}
        </ProConWrapper>
      )}
    </Wrapper>
  );
};
