import algoliasearch from 'algoliasearch/lite';

type Hit = {
  objectID: string;
};
export type CompanyHit = Hit & {
  id: number;
  name: string;
  city: string;
  slug: string;
};

export const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_API_KEY
);

export const ALGOLIA_INDEX = {
  COMPANIES: process.env.NEXT_PUBLIC_ALGOLIA_INDEX_COMPANIES || 'staging-companies',
} as const;

type AlgoliaIndex = typeof ALGOLIA_INDEX[keyof typeof ALGOLIA_INDEX];

export const searchableAttributes: Record<AlgoliaIndex, (language: string) => string> = {
  [ALGOLIA_INDEX.COMPANIES]: (language: string) => `name,city,${language}.sectors.lvl1`,
};

export const sortedIndices: AlgoliaIndex[] = [ALGOLIA_INDEX.COMPANIES];
