import styled from 'styled-components';

export const ContentWrapper = styled.div`
  margin-top: 2rem;
`;

export const ContentListTitle = styled.h3`
  margin: 0 0 1rem 0;
`;

export const ContentList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ContentListItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  img {
    max-width: 10rem;
    height: auto;
    margin: 0 1rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  @media (max-width: 800px) {
    flex-direction: column;
    margin: 0 1rem;
  }
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  font-size: 1.4rem;
  margin-left: 1rem;
  text-decoration: underline;
  cursor: pointer;
  @media (max-width: 800px) {
    margin-left: 0;
    margin-top: 1rem;
  }
  ${Wrapper}:hover & {
    color: black;
  }
`;

export const OfSpan = styled.label`
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  font-size: 1.4rem;
  margin: 0 1rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

export const ContentUploadButtons = styled.div`
  display: flex;
  font-weight: 700;
  gap: 1rem;
  @media (max-width: 800px) {
    justify-content: center;
  }
`;

export const SvgWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.palette.grey['500']};
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  svg {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    svg {
      width: 45px;
      height: 45px;
    }
  }
`;
