import { ErrorMessage, Input } from '@homeproved/shared/feature-forms';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReadOnlyHiddenField, ReadOnlyValue } from '../Atoms';
import { Title } from './description/Atoms';
import { useFormContext } from 'react-hook-form';
import { Box, CircularProgress } from '@material-ui/core';
import { StyledTextArea } from '../WriteReviewForm';
import { apiFactory, ReviewsApiFactory, useMutationFetch } from '@homeproved/shared/data-access';
import { Language } from '@homeproved/shared/feature-i18n';

type Props = {
  isActive: boolean;
  readOnly: boolean;
  locale?: Language;
  score?: string;
  companyId?: number;
  proConPoints?: any[];
};

export const Collab: FC<Props> = ({
  companyId,
  readOnly,
  isActive,
  score,
  locale,
  proConPoints,
}) => {
  const { t } = useTranslation();
  const { register, errors, getValues, setValue } = useFormContext();
  const reviewsApi = apiFactory(locale, ReviewsApiFactory);
  const [collaberation, setCollaberation] = React.useState('');
  const {
    mutation: { isSuccess, mutate, data, isLoading },
  } = useMutationFetch('reviewAiPost', (body) => reviewsApi.apiReviewSuggestionAiGet(body));
  const { location, description } = getValues();

  useEffect(() => {
    if (!companyId || !location || !description || !score || !proConPoints?.length) return;
    mutate({
      companyId: companyId.toString(),
      city: location,
      typeOfWork: description,
      rating: score,
      pointsProConIds: proConPoints,
    });
  }, [companyId, location, description, score, proConPoints]);

  useEffect(() => {
    if (isSuccess) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const reviewSuggestion = data?.message;
      setValue('collaberation', reviewSuggestion);
      setCollaberation(reviewSuggestion);
    }
  }, [isSuccess, data]);

  return (
    <Box display={isActive ? 'block' : 'none'}>
      <Title>{t('reviews.write.collab.title', 'Hoe is de samenwerking verlopen?')}</Title>
      <Box fontSize={14} mb={2}>
        {t(
          'reviews.write.collab.subtitle',
          'Wij geven alvast een aanzet! Je kan de tekst zo publiceren of wijzigen indien nodig.'
        )}
      </Box>
      <ReadOnlyHiddenField readOnly={readOnly}>
        <Box position="relative">
          <StyledTextArea
            rows={5}
            disabled={isLoading}
            name="collaberation"
            loading={isLoading}
            value={collaberation}
            onChange={(e) => {
              setCollaberation(e.target.value);
              setValue('collaberation', e.target.value);
            }}
            defaultValue={getValues().collaberation}
            placeholder={t('reviews.write.collab.placeholder', 'Schrijf hier je ervaring')}
            {...register('collaberation')}
          />
          {isLoading && (
            <Box position="absolute" top="30%" left="45%">
              <CircularProgress />
            </Box>
          )}
        </Box>
        {errors.collaberation && <ErrorMessage>{t(errors.collaberation.message)}</ErrorMessage>}
      </ReadOnlyHiddenField>
      {readOnly && <ReadOnlyValue>{getValues().collaberation}</ReadOnlyValue>}

      <Box mt={2}>
        <Title>{`${t('reviews.write.collab.name.title', 'Jouw naam bij de review')}`}</Title>
        <Input
          name="screenName"
          onChange={(e) => setValue('screenName', e.target.value)}
          {...register('screenName')}
        />
        {errors.screenName && <ErrorMessage>{t(errors.screenName.message)}</ErrorMessage>}
        {readOnly && <ReadOnlyValue>{getValues().screenName}</ReadOnlyValue>}
      </Box>
    </Box>
  );
};
