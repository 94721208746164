import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DropzoneInputProps } from 'react-dropzone';
import { Label, SvgWrapper } from './Atoms';

type AddImagesButtonProps = {
  inputProps: DropzoneInputProps;
  onChange: (files: FileList) => void;
};

export const AddImagesButton: FC<AddImagesButtonProps> = ({ inputProps, onChange }) => {
  const { t } = useTranslation();

  return (
    <SvgWrapper>
      <SvgIcon viewBox="0 0 51 51">
        <defs>
          <style>{'.e{fill:#dc1239}.f{fill:#e44338}'}</style>
        </defs>
        <g id="b">
          <g id="c">
            <path
              className="e"
              d="M37.74 36.9H13.26c-1.23 0-2.23-1-2.23-2.23V16.9c0-1.23 1-2.23 2.23-2.23h24.49c1.23 0 2.23 1 2.23 2.23v17.77c0 1.23-1 2.23-2.23 2.23ZM13.26 15.8c-.61 0-1.1.49-1.1 1.1v17.77c0 .61.49 1.1 1.1 1.1h24.49c.61 0 1.1-.49 1.1-1.1V16.9c0-.61-.49-1.1-1.1-1.1H13.26Z"
            />
            <path
              className="f"
              d="M13.26 36.9a.55.55 0 0 1-.32-.1.553.553 0 0 1-.14-.78l8.44-12.27c.1-.15.27-.24.46-.24.2 0 .36.08.46.23l4.27 5.85 2.76-2.7c.11-.11.25-.17.41-.16.15 0 .3.07.4.19l8.16 9.05c.21.23.19.59-.04.8-.23.21-.59.19-.8-.04l-7.77-8.61-2.81 2.75a.55.55 0 0 1-.44.16.575.575 0 0 1-.41-.23l-4.19-5.74-7.99 11.62c-.11.16-.29.24-.47.24ZM31.91 24.63c-1.47 0-2.67-1.2-2.67-2.67s1.2-2.67 2.67-2.67 2.67 1.2 2.67 2.67-1.2 2.67-2.67 2.67Zm0-4.22c-.85 0-1.55.69-1.55 1.55s.69 1.55 1.55 1.55 1.55-.69 1.55-1.55-.69-1.55-1.55-1.55Z"
            />
          </g>
        </g>
      </SvgIcon>
      <Label htmlFor="fileInput">{t('reviews.write.description.addImages')}</Label>
      <input {...inputProps} onChange={(e) => onChange(e.target.files)} />
    </SvgWrapper>
  );
};
