import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReviewPreview } from './review-preview/ReviewPreview';
import { Button, Modal } from '@homeproved/shared/ui';
import {
  Bottom,
  Circle,
  PriceTitle,
  PriceValue,
  Subtitle,
  ThumbsUp,
  Title,
  Top,
  StyledA,
} from './thanks/Atoms';
import { ReviewData } from '@homeproved/shared/data-access';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import Link from 'next/link';
import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import styled from 'styled-components';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from 'react-share';

type WriteReviewThanksPageProps = {
  review: ReviewData;
  getPath: GetPathFunction;
};

const SocialMediaWrapper = styled.div`
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ContestRulesLink = styled.a`
  text-align: center;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  color: inherit;
  margin-top: 1rem;
  display: inline-block;
`;

export const WriteReviewForCompanyThanksPage: FC<WriteReviewThanksPageProps> = ({
  review,
  getPath,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xs));
  const translationBaseKey = 'app.com.pages.reviews';
  const [showShareModal, setShowShareModal] = useState<boolean>(false);

  const url =
    process.env.NEXT_PUBLIC_COM_URL +
    getPath('/company/:slug/review/:rid', {
      slug: review.relations.company.data.slug,
      rid: review.id.toString(),
    });

  return review == null ? null : (
    <>
      <Top>
        <Title>
          {t(`${translationBaseKey}.thanks`)}
          <ThumbsUp src="/approved2.png" alt="" />
        </Title>
        <Subtitle>
          {t(`${translationBaseKey}.thanksAbout`)}
          <Link
            href={getPath('/company/:slug/reviews', { slug: review.relations.company.data.slug })}
            passHref
          >
            <StyledA
              href={getPath('/company/:slug/reviews', { slug: review.relations.company.data.slug })}
            >
              {review.relations.company.data.name}
            </StyledA>
          </Link>
        </Subtitle>
      </Top>
      <Circle mobile={isMobile}>
        <ReviewPreview
          initials={review?.initials}
          screenName={review.screenName}
          score={review.rating}
          title={review.title}
          date={review.createdAt}
          body={review.description}
          proConPoints={review.relations.proConPoints}
        />
      </Circle>
      <Bottom>
        <Button onClick={() => setShowShareModal(true)}>
          {t(`${translationBaseKey}.shareReview`)}
        </Button>
        <PriceTitle>{t(`${translationBaseKey}.chanceToWin`)}</PriceTitle>
        <img src="/bongobon.png" alt="" />
        <PriceValue>
          {t(`${translationBaseKey}.valueAbbreviation`)} {t(`${translationBaseKey}.value`)}
        </PriceValue>
        <Link href={getPath('/contest-rules')} passHref>
          <ContestRulesLink href={getPath('/contest-rules')}>
            {t('app.com.pages.reviews.contestRulesLink')}
          </ContestRulesLink>
        </Link>
      </Bottom>
      <Modal
        open={showShareModal}
        onClose={() => setShowShareModal(false)}
        maxWidth={isMobile && window.innerWidth * 0.085}
        paddingBottomMobile={3}
        height="auto"
        centerTitleMobile
        borderRadiusMobile={1}
        width="45rem"
      >
        <SocialMediaWrapper>
          <FacebookShareButton url={url}>
            <Box display="flex" flexDirection="column" alignItems="center" pl={1} pr={1}>
              <FacebookIcon size={40} borderRadius={5} />
              {'Facebook'}
            </Box>
          </FacebookShareButton>
          <TwitterShareButton url={url}>
            <Box display="flex" flexDirection="column" alignItems="center" pl={1} pr={1}>
              <TwitterIcon size={40} borderRadius={5} />
              {'Twitter'}
            </Box>
          </TwitterShareButton>
          <LinkedinShareButton url={url}>
            <Box display="flex" flexDirection="column" alignItems="center" pl={1} pr={1}>
              <LinkedinIcon size={40} borderRadius={5} />
              {'Linkedin'}
            </Box>
          </LinkedinShareButton>
        </SocialMediaWrapper>
      </Modal>
    </>
  );
};
