import React, { FC } from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Text, ReportProblemWrapper, ReportProblemTitle, AssessmentPolicyButton } from './Atoms';
import { ReviewDataRelations } from '@homeproved/shared/data-access';
import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import ReactHtmlParser from 'react-html-parser';
import { Button, Icons } from '@homeproved/shared/ui';

type ReportProblemProps = {
  relations: ReviewDataRelations;
  getComPath: GetPathFunction;
  onClickReport: () => void;
};

export const ReportProblem: FC<ReportProblemProps> = ({ relations, getComPath, onClickReport }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isLargeDesktop = useMediaQuery(theme.breakpoints.up(1680));
  const isLargeTablet = useMediaQuery(theme.breakpoints.up(1150));

  return (
    <ReportProblemWrapper isLargeDesktop={isLargeDesktop} isLargeTablet={isLargeTablet}>
      <ReportProblemTitle>
        {relations?.complaint
          ? t('app.pro.pages.reviewDetail.buttons.reported')
          : t('app.pro.pages.reviewDetail.reportProblem.title')}
      </ReportProblemTitle>
      {relations?.complaint && (
        <>
          <Text size={1.2}>
            {ReactHtmlParser(`${t('app.pro.pages.reviewDetail.reportProblem.status.text')}:
              <span>${t(
                'app.pro.pages.reviewDetail.reportProblem.status.' + relations.complaint.data.status
              )}</span><br />`)}
          </Text>
          <Box mb={1}>
            {relations.complaint.data.status === 'pending' && (
              <Text size={1.2}>{t('app.pro.pages.reviewDetail.reportProblem.textPending')}</Text>
            )}
          </Box>
        </>
      )}
      {!relations?.complaint && (
        <Box mb={1}>
          <Text size={1.2}>{t('app.pro.pages.reviewDetail.reportProblem.text')}</Text>
        </Box>
      )}
      <AssessmentPolicyButton
        variant="text"
        icon={Icons.PAPER_SCROLL}
        href={process.env.NEXT_PUBLIC_COM_URL + getComPath('/assessment-policy')}
      >
        {t('app.pro.pages.reviewDetail.reportProblem.link')}
      </AssessmentPolicyButton>
      {!relations?.complaint && (
        <Box mt={2}>
          <Button variant="dark" onClick={onClickReport} size="medium">
            {t('app.pro.pages.reviewDetail.reportProblem.cta')}
          </Button>
        </Box>
      )}
    </ReportProblemWrapper>
  );
};
