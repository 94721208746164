import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { ReviewSection, SearchTitle, SearchWrapper } from './write-reviews/Atoms';
import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import { SearchSuggestions } from './components/SearchSuggestions';

type WriteReviewPageProps = {
  getPath: GetPathFunction;
  indexName: string;
};

export const WriteReviewPage: FC<WriteReviewPageProps> = ({ getPath, indexName }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  return (
    <ReviewSection>
      <SearchTitle mobile={isMobile}>{t('reviews.search.title')}</SearchTitle>
      <SearchWrapper>
        <SearchSuggestions
          getPath={getPath}
          placeholder={t('app.com.pages.companySearch.placeholder')}
          searchMode="compact"
        />
      </SearchWrapper>
    </ReviewSection>
  );
};
