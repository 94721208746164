import React, { FC, useState } from 'react';
import { AddImagesButton } from './content/AddImagesButton';
import { AddVideoButton } from './content/AddVideoButton';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { RemoveImageButton } from '../Atoms';
import { AddMoreImagesButton } from './content/AddMoreImagesButton';
import {
  ContentList,
  ContentListItem,
  ContentListTitle,
  ContentWrapper,
  ContentUploadButtons,
} from './content/Atoms';
import { ErrorMessage } from '@homeproved/shared/feature-forms';
import { useTheme } from '@material-ui/core';
import imageCompression from 'browser-image-compression';

type Props = {
  readOnly: boolean;
  onChange: (images: ImageList) => void;
};

export type ImageList = Record<number, File>;

let fileIdAutoIncrement = 0;

export const ContentUpload: FC<Props> = ({ readOnly, onChange }) => {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState<ImageList>({});
  const [errors, setErrors] = useState<Record<string, unknown>>({});
  const theme = useTheme();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFileInput = async (files: Record<string, any>) => {
    const newContent = selectedFiles;
    for (let i = 0; i < Object.values(files).length; i++) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
      };
      if (files[i]?.type?.includes('image')) {
        const compressedFile = await imageCompression(files[i], options);
        console.log(compressedFile.size / 1024 / 1024);
        const compressedImage = new File([compressedFile], compressedFile.name);
        newContent[fileIdAutoIncrement] = compressedImage || files[i];
      } else {
        //video
        newContent[fileIdAutoIncrement] = files[i];
      }
      fileIdAutoIncrement++;
      setErrors({});
    }
    setSelectedFiles(newContent);
    onChange(newContent);
  };

  const selectedFilesHasImage = () => {
    return Object.values(selectedFiles).some((e) => e?.type?.includes('image'));
  };

  const handleRemoveImage = (id: number) => {
    const currentImages = selectedFiles;
    delete currentImages[id];
    setSelectedFiles({
      ...currentImages,
    });
  };

  return readOnly ? (
    <>
      {Object.keys(selectedFiles).length > 0 && (
        <ContentWrapper>
          <ContentListTitle>{t('reviews.write.images.title')}</ContentListTitle>
          <ContentList>
            {Object.entries(selectedFiles).map((entry, index) => (
              <ContentListItem key={index}>
                <img src={URL.createObjectURL(entry[1])} alt={entry[1].name} />
                <RemoveImageButton onClick={() => handleRemoveImage(parseInt(entry[0]))} />
              </ContentListItem>
            ))}
          </ContentList>
        </ContentWrapper>
      )}
    </>
  ) : (
    <ContentWrapper>
      <Dropzone
        accept={'image/jpeg, image/png, video/*'}
        onDrop={(acceptedFiles) => handleFileInput({ ...acceptedFiles })}
        maxSize={67108864}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            {Object.keys(selectedFiles).length === 0 ? (
              <ContentUploadButtons>
                <AddImagesButton inputProps={getInputProps()} onChange={handleFileInput} />
                <AddVideoButton inputProps={getInputProps()} onChange={handleFileInput} />
              </ContentUploadButtons>
            ) : (
              <>
                <ContentListTitle>
                  {selectedFilesHasImage() ? t('reviews.write.images.title') : 'Video'}
                </ContentListTitle>
                <ContentList>
                  {Object.entries(selectedFiles).map((entry, index) => (
                    <ContentListItem key={index}>
                      <img src={URL.createObjectURL(entry[1])} alt={entry[1].name} />
                      <RemoveImageButton
                        onClick={(event) => {
                          event.stopPropagation();
                          handleRemoveImage(parseInt(entry[0]));
                        }}
                      />
                    </ContentListItem>
                  ))}
                </ContentList>
                {errors.upload && (
                  <ErrorMessage color={theme.palette.primary.main}>{errors.upload}</ErrorMessage>
                )}
                <AddMoreImagesButton inputProps={getInputProps()} onChange={handleFileInput} />
              </>
            )}
          </div>
        )}
      </Dropzone>
    </ContentWrapper>
  );
};
