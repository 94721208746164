import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DropzoneInputProps } from 'react-dropzone';
import { SvgWrapper, Label } from './Atoms';

type AddVideoButtonProps = {
  inputProps: DropzoneInputProps;
  onChange: (files: FileList) => void;
};

export const AddVideoButton: FC<AddVideoButtonProps> = ({ inputProps, onChange }) => {
  const { t } = useTranslation();

  return (
    <SvgWrapper>
      <SvgIcon viewBox="0 0 51 51">
        <defs>
          <style>{'.e{fill:#dc1239}.f{fill:#e44338}'}</style>
        </defs>
        <g id="b">
          <g id="c">
            <path
              className="e"
              d="M37.74 36.9H13.26c-1.23 0-2.23-1-2.23-2.23V16.9c0-1.23 1-2.23 2.23-2.23h24.49c1.23 0 2.23 1 2.23 2.23v17.77c0 1.23-1 2.23-2.23 2.23ZM13.26 15.8c-.61 0-1.1.49-1.1 1.1v17.77c0 .61.49 1.1 1.1 1.1h24.49c.61 0 1.1-.49 1.1-1.1V16.9c0-.61-.49-1.1-1.1-1.1H13.26Z"
            />
            <path className="f" d="M22.49 21.07v8.86l6.62-4.49-6.62-4.37z" />
          </g>
        </g>
      </SvgIcon>
      <Label htmlFor="fileInput">{t('reviews.write.description.addVideo')}</Label>
      <input {...inputProps} onChange={(e) => onChange(e.target.files)} />
    </SvgWrapper>
  );
};
