import React, { FC } from 'react';
import styled from 'styled-components';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type ReviewShellProps = {
  currentStep: number;
  qr: boolean;
};

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

const Content = styled(({ mobile, qr, ...restProps }) => <div {...restProps} />)`
  position: relative;
  margin: ${({ mobile, qr }) =>
    `${qr ? 'none' : mobile ? '3rem auto 2rem auto' : '5rem auto 2rem auto'}`};
  width: 100%;
  z-index: 1;
`;

export const ReviewShell: FC<ReviewShellProps> = ({ qr, currentStep, children }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xs));

  return (
    <Wrapper>
      <Content qr={qr} mobile={isMobile}>
        {children}
      </Content>
    </Wrapper>
  );
};
