import React, { FC, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  StyledReviewCard,
  Header,
  Author,
  Name,
  Body,
  Date,
  Text,
  Title,
  ProConChipWrapper,
  ReportWrapper,
  ReportText,
  Description,
  Location,
} from './Atoms';
import { MediaResponse, ReviewData } from '@homeproved/shared/data-access';
import moment from 'moment';
import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import {
  AutoTranslatedIcon,
  IconButton,
  Icons,
  ImageCarrousel,
  ProConChip,
  ProfilePicture,
  SvgIcon,
  Tooltip,
  ImageGalleryModal,
  Stars,
  ReviewCardSubHeader,
} from '@homeproved/shared/ui';
import { PublicAnswerText } from '../tooltips/PublicAnswerText';
import ReactHtmlParser from 'react-html-parser';
import { decode } from 'html-entities';
import { ReportProblem } from './ReportProblem';

export type ReviewCardBigProps = {
  isTranslation: boolean;
  isMobile: boolean;
  bigger?: boolean;
  isTablet: boolean;
  showUpgradeText?: boolean;
  showReviewControl?: boolean;
  hasComplaint?: boolean;
  activities: string[] | undefined;
  onClickAnswerPublic?: () => void;
  onClickShare?: () => void;
  onClickReport: () => void;
  onClickEditPublicAnswer?: () => void;
  onClickFavorite?: () => void;
  message: string;
  canAnswer?: boolean;
  getComPath: GetPathFunction;
  blueBorder?: boolean;
  userView?: boolean;
  companyName?: string;
  isShared?: boolean;
  companySlug: string;
} & ReviewData;

export const ReviewCardBig: FC<ReviewCardBigProps> = ({
  isTranslation,
  rating,
  screenName,
  initials,
  createdAt,
  title,
  description,
  hasComplaint,
  isMobile,
  activities,
  relations,
  files,
  audio,
  video,
  picture,
  videoThumbnail,
  isFavorite,
  isShared,
  showUpgradeText,
  showReviewControl,
  onClickAnswerPublic,
  onClickShare,
  onClickReport,
  onClickEditPublicAnswer,
  onClickFavorite,
  message,
  canAnswer,
  getComPath,
  blueBorder,
  userView,
  companyName,
  bigger,
  id,
  city,
  typeOfWork,
  companySlug,
  collaberation = relations.sectors?.map((sector) => sector?.data?.name).join(', '),
  location = relations.company?.data?.city,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [initialSlide, setInitialSlide] = useState<number>(2);
  const theme = useTheme();
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.md));

  const handleClick = (event, index = initialSlide) => {
    setInitialSlide(index);
    setOpen((open) => !open);
  };

  const review = (
    <>
      <Header fullWidth userView={userView} mobile={isMobile}>
        <Author>
          <ProfilePicture
            initials={initials}
            picture={picture}
            size={isMobile ? 5 : 6}
            fontSize={isMobile ? 5 : 6}
          />
          <Box display="flex" flexDirection="column" ml={isMobile ? 1 : 3}>
            <Name variant="body1" fontSize={isMobile ? 1.6 : 2.2}>
              {screenName}
            </Name>
            <Date variant="body1" fontSize={isMobile ? 1.2 : 1.4}>
              {moment(createdAt).format('DD/MM/YY')}
            </Date>
          </Box>
          <Box ml={'auto'}>
            <Stars count={rating} size={isMobile ? 1.8 : 2.2} showRating={true} />
          </Box>
        </Author>
        {/*{isMobile && (*/}
        {/*  <RatingBalloon color={color} mobile={isMobile}>*/}
        {/*    {rating}*/}
        {/*  </RatingBalloon>*/}
        {/*)}*/}
      </Header>
      {city && typeOfWork && (
        <ReviewCardSubHeader>
          <img src="/location.svg" />
          {city}
          <img src="/work.svg" />
          {typeOfWork}
        </ReviewCardSubHeader>
      )}
      {!!decode(collaberation).length && (
        <Description>
          <Box>
            {t('reviews.description.activities', 'Uitgevoerde werken')}:&nbsp;
            <span>{decode(collaberation)}</span>
          </Box>
          {location && (
            <Location>
              <SvgIcon icon={Icons.LOCATION_OUTLINE} size={2} />
              <span>{location}</span>
            </Location>
          )}
        </Description>
      )}

      <Body mobile={isMobile}>
        <Box maxWidth={'100%'} mb={2}>
          <Box display="flex" justifyContent="space-between">
            <Title variant="body1" mobile={isMobile}>
              {isTranslation && isMobile && (
                <AutoTranslatedIcon position="inText">
                  <Tooltip title={ReactHtmlParser(t('reviews.autoTranslation'))}>
                    <span>
                      <SvgIcon icon={Icons.AUTO_TRANSLATED} size={2} />
                    </span>
                  </Tooltip>
                </AutoTranslatedIcon>
              )}
              {decode(title)}
            </Title>
            {isMobile && userView && (
              <IconButton icon={Icons.FLAG} variant="white" onClick={onClickReport} />
            )}
          </Box>
          {!!decode(description).length && (
            <Box mb={3}>
              <Text
                variant="body1"
                size={isMobile ? 1.2 : 1.4}
                lineHeight={isMobile ? 2.1 : 2.8}
                grey
                style={isMobile ? {} : { minHeight: '7rem' }}
              >
                {decode(description)}
              </Text>
            </Box>
          )}
          {files !== null && (
            <Box mb={3}>
              <ImageCarrousel
                images={files as MediaResponse[]}
                isMobile={isMobile}
                card={false}
                handleClick={(index, event) =>
                  handleClick(
                    event,
                    index !== (bigger ? (isMobile ? 2 : 4) : 3)
                      ? index
                      : files.length <= (bigger ? (isMobile ? 2 : 5) : 4)
                      ? index
                      : 0
                  )
                }
              />
            </Box>
          )}
          {relations?.proConPoints.length > 0 && (
            <Box mb={3}>
              <ProConChipWrapper>
                {relations.proConPoints.map(({ data }) => (
                  <ProConChip
                    key={data.id}
                    pro={data.type === 'pro'}
                    text={data.label}
                    mobile={isMobile}
                  />
                ))}
              </ProConChipWrapper>
            </Box>
          )}
        </Box>
        {!!relations?.feedback?.data?.message && !canAnswer && (
          <PublicAnswerText
            message={canAnswer ? message : relations?.feedback?.data?.message}
            onClickEditPublicAnswer={!userView && onClickEditPublicAnswer}
            isMobile={isMobile}
            userView={userView}
            companyName={companyName}
          />
        )}
        {/*{!isMobile && <RatingBalloon color={color}>{rating}</RatingBalloon>}*/}
      </Body>
      {!isDesktop && !userView && !hasComplaint && (
        <ReportProblem
          relations={relations}
          getComPath={getComPath}
          onClickReport={onClickReport}
        />
      )}
      {!isMobile && userView && !hasComplaint && (
        <ReportWrapper>
          <ReportText variant="body1" onClick={onClickReport}>
            {t('shared.report.ctaReview')}
          </ReportText>
          <IconButton
            icon={Icons.FLAG}
            variant="transparent"
            onClick={onClickReport}
            tooltip={t('shared.report.tooltipReview')}
            iconColor={theme.palette.grey[700]}
          />
        </ReportWrapper>
      )}
      {isTranslation && !isMobile && (
        <AutoTranslatedIcon position="topLeft">
          <Tooltip title={ReactHtmlParser(t('reviews.autoTranslation'))}>
            <span>
              <SvgIcon icon={Icons.AUTO_TRANSLATED} size={2} />
            </span>
          </Tooltip>
        </AutoTranslatedIcon>
      )}
    </>
  );

  return (
    <>
      <StyledReviewCard mobile={!isDesktop} blueBorder={blueBorder} userView={userView}>
        {userView ? (
          review
        ) : (
          <a
            href={getComPath('/company/:slug/review/:rid', {
              slug: companySlug,
              rid: id.toString(),
            })}
          >
            {review}
          </a>
        )}
      </StyledReviewCard>
      {files !== null && (
        <ImageGalleryModal
          open={open}
          setOpen={setOpen}
          images={files}
          initialSlide={initialSlide}
        />
      )}
    </>
  );
};
